section.why{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: var(--primary-color);
    padding: 50px;
    gap: 4rem;
    background-color: #f3f3f3;
}

section.why > div h1{
    font-size: 2rem;
    color: var(--primary-color);
}

section.why > div:nth-child(1){
    text-align: center;
}

section.why > div:nth-child(2){
    display: flex;
    justify-content: space-around;
    gap: 15px;
}

section.why div.card-why{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 250px;
    width: 300px;
    gap: 15px;
    background-color: white;
    padding: 30px 15px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

section.why > div:nth-child(2) div img{
    width: 50px;
}

section.why div.card-why .icon{
    height: 50px;
}

@media (max-width: 768px) {


    section.destination > div{
        width: 100vw;
        gap: 2rem;
        padding-inline: 0;
        padding-block: 25px;
    }

    section.why > div:first-child{
        font-size: 1.5rem;
        padding-inline: 5px;
    }

    section.why > div:nth-child(2) {
        width: auto;
        justify-content: center;
        flex-direction: column;
    }

    section.why div.card-why{
        height: 240px;
        width: auto;
    }
}