header.header{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: transparent;
    padding: 5px 50px;
}

header .navbar{
    display: flex;
    gap: 30px;
}

.navbar a{
    color: white;
    font-weight: bold;
    font-size: 1.3rem;
    text-decoration: none;
    transition: 0.8s;
}

.navbar a:hover{
    transition: 0.8s;
    color: var(--primary-color);
}

.hamburger-menu {
    display: none;
    font-size: 30px;
    cursor: pointer;
    color: white;
}

@media (max-width: 768px) {
    header.header {
        justify-content: space-between;
        padding: 5px 25px;
    }

    .navbar {
        display: none !important; /* Esconde o menu no mobile */
    }

    .hamburger-menu {
        display: block; /* Exibe o ícone do menu hambúrguer */
    }

    .mobile-menu {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        position: absolute;
        top: 80px;
        right: 0;
        width: 100%;
        padding: 10px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        background-color: transparent;
        text-align: center;
        gap: 10px;
    }

    .mobile-menu a {
        padding: 10px;
        text-align: center;
        border-bottom: 1px solid #ddd;
        color: white;
        text-decoration: none;
        font-weight: bold;
    }
}