:root{
  --primary-color: #22376C;
  --second-color: #038C65;
  --color-white: white;
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dynalight&display=swap');

body {
  font-family: 'DM Sans', sans-serif;
}

a{
  text-decoration: none;
}

.text-center{
  text-align: center;
}

.container{
  background-image: url('../public/img/bg-hero.svg');
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

h1, h2{
  margin: 0;
}

.btn-default {
  background-color: var(--second-color);
  border: none;
  border-radius: 25px;
  padding: 20px 50px;
  color: white;
  font-size: 1rem;
  font-weight: bold;
}

.card{
  display: flex !important;
  flex-direction: column !important;
  width: 250px !important;
  color: #6D5668;
  gap: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  background-color: white;

}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
}

.card img{
  width: 250px;
  height: 188px;
}

.card .title{
  font-size: 1.2rem;
}

.card .body{
  font-size: 1rem;
}

.destination-boat{
  display: flex;
  flex-direction: column;
  gap: 5rem;
  background-image: url(../public/img/bg-why.webp);
  background-repeat: no-repeat;
  background-size: cover;
}