section#boat{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

section#boat > div{
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 1200px;
    gap: 2rem;
    background-color: rgba(243, 243, 243, 0.9);
    border-radius: 25px;
}

section#boat > div > div:nth-child(1){
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    color: var(--primary-color);
}

section#boat > div > div:nth-child(1) h1{
    font-size: 2rem;
}

section#boat > div > div:last-child{
    display: flex;
    justify-content: space-between;
    height: 400px;
    flex: 1;
}

section#boat .about-boat{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    color: #6D5668;
}

@media (max-width: 768px) {

    section#boat > div{
        width: 100vw;
        padding-inline: 0;
        padding-block: 25px;
    }

    section#boat > div > div:last-child{
        flex-direction: column-reverse;
        gap: 2rem;
    }

    section#boat .about-boat{
        text-align: center;
    }

}



