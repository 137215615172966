section#exclusive{
    display: flex;
    justify-content: center;
}

section#exclusive > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 700px;
    padding: 20px;
    gap: 2rem;
    background-color: #038C653D;
    border-radius: 25px;
    backdrop-filter: blur(10px);
}

section#exclusive div h1{
    font-size: 2rem;
    color: var(--second-color);
}

section#exclusive div h2{
    font-size: 1.5rem;
    color: var(--second-color);
}

.card-destination.exclusive{
    width: 300px;
    height: 300px;
}

.card-destination.exclusive img{
    width: 300px;
    height: 300px;
}

@media (max-width: 768px) {

    section#exclusive > div{
        padding-inline: 0;
        padding-block: 25px;
    }

    .card-destination.exclusive{
        width: max-content;
        height: max-content;
    }

    .card-destination.exclusive img{
        width: max-content;
        height: 300px;
    }

}