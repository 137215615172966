section.reserve{
    display: flex;
    justify-content: space-around;
    gap: 30px;
    padding: 50px;
    align-items: center;
}

section.reserve > div:nth-child(2) h1{
    color: var(--primary-color);
}


section.reserve div:last-child{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

section.reserve div:last-child span{
    text-align: center;
    color: var(--primary-color);
    font-weight: bold;
}

@media (max-width: 768px) {

    section.reserve{
        flex-direction: column;
        padding: 20px 0px;
        gap: 2rem;
    }
}