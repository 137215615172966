section.destination{
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    text-align: center;
    align-items: center;
}

section.destination > div{
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 1200px;
    gap: 2rem;
    background-color: rgba(243, 243, 243, 0.9);
    border-radius: 25px;
    

}

section.destination > div > div:nth-child(1){
    display: flex;
    flex-direction: column;
    gap: 2rem;
}


section.destination .header h1{
    font-size: 2rem;
    color: var(--primary-color);
}

section.destination .header h2{
    font-size: 1.3rem;
    color: var(--primary-color);
}

.slick-list{
    height: 350px;
}

.card-destination{
    display: flex;
    flex-direction: column;
    width: 200px;
    color: #6D5668;
    gap: 15px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    background-color: white;
  
}
  
.card-destination:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
}
  
.card-destination img{
    width: 200px;
    height: 150px;
}
  
.card-destination .title{
    font-size: 1.2rem;
}
  
.card-destination .body{
    font-size: 1rem;
}

div.cards-destination{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
}

@media (max-width: 768px) {

    div.cards-destination {
        flex-wrap: initial;
        width: 100vw;
        overflow: scroll;
        justify-content: start;
    }


}

