footer{
    display: flex;
    flex-direction: column;
}

footer > div:first-child{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-color);
    padding: 50px;
    color: white;
}

footer div.icons{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.div-contact{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.div-email{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

footer > div:last-child{
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding: 5px;
    color: var(--primary-color);
}

a{
    color: white;
}

@media (max-width: 768px) {

    footer > div:first-child{
        flex-direction: column;
        gap: 2rem;
    }

    footer .div-local{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
    }
}