.swiper {
    width: 500px;
    height: 100%;
    margin: 0;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-boat{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-wrapper{
    align-items: center;
}

@media (max-width: 768px) {

    .swiper{
        width: auto;
    }

}