section#hero {
    height: calc(100vh - 87px);
    display: flex;
    align-items: center;
}

section#hero > div {
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px;
    padding-left: 100px;
}

section#hero > div h1 {
    color: white;
    font-size: 5rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

section#hero > div > h2 {
    font-size: 3rem;
    font-family: 'Dynalight', cursive;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {

    section#hero > div {
        padding: 0;
        height: 80vh;
        justify-content: end;
        text-align: center;
        overflow: hidden;
        
    }

    .section#hero > div h1{
        color: white;
        font-size: 4rem;
        text-align: center;
    }
}